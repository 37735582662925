/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~handsontable/dist/handsontable.full.css';

@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRa1RVmPjeKy21_GQJaLlJI.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
html,
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Open Sans Light', sans-serif;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/*global variables*/
:root {
  --bottom-nav-height: 55vh; /*calc(100vh - 64px);5px;*/
}

/*** Drag & Drop ***/
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

/*for hansontable  */
.integer--error,
.highlight--error,
.depth--error,
.toit-base--error,
.interval-3-9--error {
  font-weight: bold;
}
.integer--error:hover,
.highlight--error:hover,
.depth--error:hover,
.toit-base--error:hover,
.interval-3-9--error:hover {
  background-color: black;
  color: white;
}
.highlight--error::after,
.highlight--error:hover::after {
  content: ' (Must be numeric)';
}

.integer--error::after,
.integer--error:hover::after {
  content: ' (Must be integer)';
}

.depth--error::after,
.depth--error:hover::after {
  content: ' (Must be positive numeric and no duplicate)';
}

.toit-base--error::after,
.toit-base--error:hover::after {
  content: ' (Must be numeric and in increased order for toit/base)';
}

.interval-3-9--error::after,
.interval-3-9--error:hover::after {
  content: ' (Must be between 3 and 9)';
}

.smallFont {
  font-size: 14px;
}

.alert-message {
  color: orange;
  font-weight: bold;
  font-size: 12px;
}

/*for swal error modal*/
.swal-error {
  width: 350px !important;
  font-size: 10px !important;
}

.swal-wide-sb {
  width: 850px !important;
}

/* used by multiple components*/
.bottom-container {
  padding: 0px 10px 5px 10px;
  height: 100%;
  background-image: url('assets/tramecharte.png');
  background-color: white;
  overflow: auto;
  z-index: 2;
}
.table-container {
  height: calc(var(--bottom-nav-height) - 120px);
}

.edit-button-state0 {
  cursor: not-allowed;
  opacity: 0.6;
}

.edit-button-state1 {
  cursor: pointer;
}

.edit-button-state2 {
  margin-bottom: var(--bottom-nav-height);
  cursor: pointer;
}

.resize-button {
  position: fixed;
  bottom: calc(var(--bottom-nav-height) - 28px);
  z-index: 100;
  right: 50px;
}

.red {
  color: #e20025;
}
.orange {
  color: orange;
}
.green {
  color: green;
}

.htRight {
  text-align: left !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.line-break-tooltip {
  white-space: pre-line;
  font-size: 13px;
}

.hidden {
  display: none !important;
}

.mat-autocomplete-panel.search-box-panel .mat-option.mat-active {
  border: solid #0000ffcc;
}

.correlation-data-reference-tooltip {
  font-size: 15px;
}
